import CustomScroll from 'react-custom-scroll';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LocomotiveScroll from 'locomotive-scroll';
import useMouse from '@react-hook/mouse-position'
import {useModal} from "../context";
import {Link, useNavigate, Navigate} from 'react-router-dom'
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import React, {useState, useRef, useEffect, useLayoutEffect} from "react";
import ReactPlayer from "react-player/lazy";

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger);
}

//let stMain = null;

const desktop = window.matchMedia("(min-width: 1024px)").matches;

function moveElementsToEndOfArray(arr, x) {
    let newArr = JSON.parse(JSON.stringify(arr));
    x = x % (newArr.length);
    for (let i = 0; i < x; i++) {
        newArr.push(arr[i]);
    }
    newArr.splice(0, x);
    return newArr;
}

const projects = [
    {
        id: 1,
        title: 'На крючке: когда звонит мошенник',
        descr: 'Как телефонные мошенники обманывают миллионы россиян и похищают миллиарды рублей? Документальный триллер-расследование о том, как устроен преступный бизнес мошеннических колл-центров и как не стать их жертвой',
        url: 'https://okko.tv/serial/na-krjuchke-592260973',
        tags: ['сценарий', 'съёмки', 'монтаж', 'дизайн', 'графика'],
        prev: require("../assets/projects/na-kruchke/prev.jpg"),
        prevWebp: require("../assets/projects/na-kruchke/prev.webp"),
        prevMb: require("../assets/projects/na-kruchke/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/na-kruchke/prev-mb.webp"),
        video: require("../assets/projects/na-kruchke/video.mp4"),
        videoMb: require("../assets/projects/na-kruchke/video-mb.mp4"),
        special: require("../assets/projects/na-kruchke/video.mp4"),
        specialMb: require("../assets/projects/na-kruchke/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default,
    },
    {
        id: 2,
        title: 'Свежий русский',
        descr: 'Они создают русский стиль сегодня и будут в моде завтра! Серия VK-клипов для проекта «Ленты.ру» о молодых российских дизайнерах.',
        url: 'https://vk.com/clips-67991642?z=clip-67991642_456302006',
        tags: ['сценарий', 'съёмки', 'монтаж', 'дизайн', 'графика'],
        prev: require("../assets/projects/svejiy-russkiy/prev.jpg"),
        prevWebp: require("../assets/projects/svejiy-russkiy/prev.webp"),
        prevMb: require("../assets/projects/svejiy-russkiy/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/svejiy-russkiy/prev-mb.webp"),
        video: require("../assets/projects/svejiy-russkiy/video.mp4"),
        videoMb: require("../assets/projects/svejiy-russkiy/video-mb.mp4"),
        special: require("../assets/projects/svejiy-russkiy/video.mp4"),
        specialMb: require("../assets/projects/svejiy-russkiy/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default
    },
    {
        id: 3,
        title: 'Русская игра',
        descr: 'Главные русские инди-игры, ставшие мировыми хитами! Серия VK-клипов и 10 выпусков шоу для проекта «Газеты.Ru» о российских играх.',
        url: 'https://vk.com/video/playlist/-20169232_1',
        tags: ['сценарий', 'съёмки', 'монтаж', 'дизайн', 'графика'],
        prev: require("../assets/projects/russkaya-igra/prev.jpg"),
        prevWebp: require("../assets/projects/russkaya-igra/prev.webp"),
        prevMb: require("../assets/projects/russkaya-igra/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/russkaya-igra/prev-mb.webp"),
        video: require("../assets/projects/russkaya-igra/video.mp4"),
        videoMb: require("../assets/projects/russkaya-igra/video-mb.mp4"),
        special: require("../assets/projects/russkaya-igra/video.mp4"),
        specialMb: require("../assets/projects/russkaya-igra/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default
    },
    {
        id: 4,
        title: 'Алгоритм',
        descr: 'Манипуляции, слежка, и мрачный киберпанк. Документальный сериал-исследование о том, как социальные сети влияют на миллиарды людей и проникают во все сферы нашей жизни',
        url: 'https://www.kinopoisk.ru/series/5265118/',
        tags: ['сценарий', 'съёмки', 'монтаж', 'дизайн', 'графика'],
        prev: require("../assets/projects/algoritm/prev.jpg"),
        prevWebp: require("../assets/projects/algoritm/prev.webp"),
        prevMb: require("../assets/projects/algoritm/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/algoritm/prev-mb.webp"),
        video: require("../assets/projects/algoritm/video.mp4"),
        videoMb: require("../assets/projects/algoritm/video-mb.mp4"),
        special: require("../assets/projects/algoritm/special.mp4"),
        specialMb: require("../assets/projects/algoritm/special-mb.mp4"),
        logo: require("../assets/img/logo-production.svg").default
    },
    {
        id: 5,
        title: 'Новое общество',
        descr: 'Экологичный транспорт, электромобили, новые источники энергии, переработка мусора и четырехдневная рабочая неделя — документальный сериал о том, каким будет наше будущее. Каждый из пяти эпизодов проекта «Новое общество» — исследование прорывных идей и технологий, которые уже стали реальностью и навсегда изменят наш мир.',
        url: 'https://www.kinopoisk.ru/series/5265111/',
        tags: ['сценарий', 'съёмки', 'монтаж', 'дизайн', 'графика'],
        prev: require("../assets/projects/no/prev.jpg"),
        prevWebp: require("../assets/projects/no/prev.webp"),
        prevMb: require("../assets/projects/no/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/no/prev-mb.webp"),
        video: require("../assets/projects/no/video.mp4"),
        videoMb: require("../assets/projects/no/video-mb.mp4"),
        logo: require("../assets/img/logo-production.svg").default
    },
    {
        id: 6,
        title: 'Проблемы первого мира',
        descr: 'Расизм и BLM, культура отмены и кризис демократии, мигранты и национализм. Первый в России документальный сериал-исследование ключевых проблем современного мира в прорывном визуальном исполнении',
        url: 'https://www.kinopoisk.ru/series/5275411/',
        tags: ['сценарий', 'монтаж', 'дизайн', 'графика'],
        prev: require("../assets/projects/ppm/prev.jpg"),
        prevWebp: require("../assets/projects/ppm/prev.webp"),
        prevMb: require("../assets/projects/ppm/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/ppm/prev-mb.webp"),
        video: require("../assets/projects/ppm/video.mp4"),
        videoMb: require("../assets/projects/ppm/video-mb.mp4"),
        logo: require("../assets/img/logo-production.svg").default
    },
    {
        id: 7,
        title: 'Русский форсаж',
        descr: 'Когда-то во Владивосток тайно попали первые в СССР японские иномарки. Потом возник самый большой авторынок России, а по местным улицам начали гонять первые дрифтеры. Документальный фильм о невероятной истории приморского дрифта.',
        url: null,
        tags: ['сценарий', 'съёмки', 'монтаж', 'дизайн', 'графика'],
        prev: require("../assets/projects/forsaj/prev.jpg"),
        prevWebp: require("../assets/projects/forsaj/prev.webp"),
        prevMb: require("../assets/projects/forsaj/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/forsaj/prev-mb.webp"),
        video: require("../assets/projects/forsaj/video.mp4"),
        videoMb: require("../assets/projects/forsaj/video-mb.mp4"),
        logo: require("../assets/img/logo-production.svg").default
    },
    {
        id: 8,
        title: 'Мировая история санкций',
        descr: 'От Кубы и Ирана до Северной Кореи. Проект о том, как живут и выживают страны под гнетом санкций',
        url: 'https://sanctions.lenta.ru/',
        tags: ['дизайн', 'разработка', 'контент'],
        prev: require("../assets/projects/sanctions/prev.jpg"),
        prevWebp: require("../assets/projects/sanctions/prev.webp"),
        prevMb: require("../assets/projects/sanctions/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/sanctions/prev-mb.webp"),
        video: require("../assets/projects/sanctions/video.mp4"),
        videoMb: require("../assets/projects/sanctions/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default,
        logoW: require("../assets/img/logo-media-wh.svg").default
    },
    // {
    //     id: 9,
    //     title: 'Что делать?',
    //     descr: 'На чем сэкономить, куда вложить деньги и как победить стресс. Проект-инструкция о спокойной жизни в кризисных ситуациях',
    //     url: 'https://whattodo.lenta.ru/',
    //     tags: ['дизайн', 'разработка', 'контент'],
    //     prev: require("../assets/projects/whattodo/prev.jpg"),
    //     prevWebp: require("../assets/projects/whattodo/prev.webp"),
    //     prevMb: require("../assets/projects/whattodo/prev-mb.jpg"),
    //     prevMbWebp: require("../assets/projects/whattodo/prev-mb.webp"),
    //     video: require("../assets/projects/whattodo/video.mp4"),
    //     videoMb: require("../assets/projects/whattodo/video-mb.mp4"),
    //     logo: require("../assets/img/logo-media.svg").default,
    //     logoW: require("../assets/img/logo-media-wh.svg").default
    // },
    // {
    //     id: 9,
    //     title: 'Время роста',
    //     descr: 'Вино, мебель и сыры. Проект о том, как развивается бизнес в России',
    //     url: 'https://time.lenta.ru/',
    //     tags: ['дизайн', 'разработка', 'контент'],
    //     prev: require("../assets/projects/time/prev.jpg"),
    //     prevWebp: require("../assets/projects/time/prev.webp"),
    //     prevMb: require("../assets/projects/time/prev-mb.jpg"),
    //     prevMbWebp: require("../assets/projects/time/prev-mb.webp"),
    //     video: require("../assets/projects/time/video.mp4"),
    //     videoMb: require("../assets/projects/time/video-mb.mp4"),
    //     logo: require("../assets/img/logo-media.svg").default,
    //     logoW: require("../assets/img/logo-media-wh.svg").default
    // }
];

function Home({loaded, setRedirect, redirect, setLogoCircleColor, setLogoType, setLogoTextColor}) {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [activePromo, setActivePromo] = useState(1);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slLoaded, setSlLoaded] = useState(false);
    const [currentSlide2, setCurrentSlide2] = useState(0);
    const [slLoaded2, setSlLoaded2] = useState(false);
    const [prCur, setPrCur] = useState(1);
    const [prPrev, setPrPrev] = useState(1);
    const ref = React.useRef(null);
    const mouse = useMouse(ref, {
        fps: 60,
        enterDelay: 100,
        leaveDelay: 100
    });
    const [showreelXY, setShowreelXY] = useState({
        x: '25.7rem',
        y: '14.9rem'
    });
    const navigate = useNavigate();

    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        loop: false,
        slides: {
            perView: 1,
            spacing: 0,
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setSlLoaded(true)
        },
    });

    useEffect(()=>{
        setLogoType('media');
        setLogoTextColor('#000000');
    }, []);

    useEffect(()=>{
        if(desktop){
            let x = mouse.x;
            let y = mouse.y;
            let el = document.getElementById('showreel-anim');
            let elW = el.offsetWidth;
            let elH = el.offsetHeight;
            let wW = window.innerWidth;
            let wH = window.innerHeight;

            if(x && x < (elW / 2 + 10)){
                x = elW / 2 + 10;
            }

            if(x && x > (wW - (elW / 2) * 1.3 - 40)){
                x = wW - (elW / 2) * 1.3 - 40;
            }

            if(y && y < (elH * 0.8)){
                y = elH * 0.8 + 10
            }

            if(y && y > (wH - elH * 0.8)){
                y = wH - elH * 0.8;
            }

            if(x && y){
                setShowreelXY({
                    x,
                    y
                });
            }
        }
    }, [mouse]);

    const [sliderRef2, instanceRef2] = useKeenSlider({
        initial: 0,
        loop: false,
        slides: {
            perView: 1,
            spacing: 0,
        },
        breakpoints: {
            '(min-width: 1024px)': {
                slides: {
                    perView: 16,
                    spacing: 0,
                },
            },
        },
        slideChanged(slider) {
            setCurrentSlide2(slider.track.details.rel);
        },
        created() {
            setSlLoaded2(true)
        },
    });

    const transformLogo = (section) => {
        let logoText = '#000';
        let logoCircle = '#70F9BB'

        if(section.classList.contains('sc__showreel')){
            logoText = '#fff';
        }

        if(section.classList.contains('sc__advants')){
            logoText = '#fff';
        }

        if(section.classList.contains('sc__contacts')){
            logoCircle = '#000'
        }

        setLogoCircleColor(logoCircle);
        setLogoTextColor(logoText);
    }

    useEffect(()=>{
        if(loaded){
            runAnim();
        }
    }, [loaded]);

    useEffect(()=>{
        if(window.location.hash){
            setTimeout(function (){
                goToAnchor(window.location.hash.substring(1));
            }, 300);
        }
    }, []);

    const goToAnchor = (anchor) => {
        let el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({behavior: 'smooth'});
            window.history.pushState({param: 'Value'}, '', '/');
        }
    }

    /*useLayoutEffect(() => {
        if(desktop){
            let el = document.getElementById('showreel-anim');
            let anim = gsap.timeline().fromTo(el, {
                x: 50 - el.offsetLeft / 2,
                y: 50 - el.offsetTop,
                ease: "linear",
            },{
                x: '24.2rem',
                y: 0,
                ease: "linear",
            }).to(el, {
                x: '-24.2rem',
                y: '22.6rem',
                ease: "linear",
            });

            let stMain = ScrollTrigger.create({
                trigger: '.sc__showreel',
                start:"top top",
                animation: anim,
                end:()=>"+=120%",
                pin: true,
                scrub: true,
            });

            return () => {
                stMain.kill();
            };
        }
    }, []);*/

    useLayoutEffect(() => {
        if(desktop){
            if(loaded){
                let scStArr = [];
                let sections = document.querySelectorAll('.section');
                sections.forEach((section) => {
                    let scStIt = ScrollTrigger.create({
                        trigger: section,
                        start: "top 40",
                        end: "bottom 40",
                        onEnter: () => {
                            transformLogo(section);
                        },
                        onEnterBack: () => {
                            transformLogo(section);
                        },
                    });
                    scStArr.push(scStIt);
                });

                return () => {
                    scStArr.forEach(it => {
                        it.kill();
                    });

                    scStArr = [];
                };
            }
        }
    }, [loaded]);

    useEffect(()=>{
        if(loaded){
            const count = desktop ? 3 : 4;
            let tl1 = gsap.timeline().from('.anim__it--' + activePromo + ' .anim__it-bg', {
                width: (desktop ? '16.4rem' : '7.2rem'),
                ease: "linear",
                yoyo: true,
                repeat: 1,
                repeatDelay: 1.3,
                duration: 0.7,
                onComplete() {
                    setActivePromo((activePromo < count ? (activePromo + 1) : 1));
                    let trg = this.targets();
                    setTimeout(function (){
                        gsap.set(trg, { clearProps: "all" });
                    }, 300);
                }
            });

            let tl2 = gsap.timeline().from('.anim__it--' + activePromo + ' .sc__title', {
                opacity: 0,
                ease: "linear",
                yoyo: true,
                repeat: 1,
                repeatDelay: 1.3,
                duration: 0.7,
                onComplete() {
                    setActivePromo((activePromo < count ? (activePromo + 1) : 1));
                    let trg = this.targets();
                    setTimeout(function (){
                        gsap.set(trg, { clearProps: "all" });
                    }, 300);
                }
            });
        }
    }, [loaded, activePromo]);

    const runAnim = () => {
        const boxes = gsap.utils.toArray('.advants__it');

        boxes.forEach((box, i) => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: box,
                    start: "top 150%",
                    end: "top",
                    scrub: true,
                    onUpdate: ({ progress }) => {
                        if(progress > 0.7) {
                            box.parentElement.classList.add('active');
                            setActiveIndex(i);
                        }else{
                            box.parentElement.classList.remove('active');
                            if(i === 0){
                                setActiveIndex(-1);
                            }
                        }
                    }
                }
            }).fromTo(box, {
                x: '50%',
            },{
                x: '0',
            });
        });

    }

    return(
        <>
            <div className="sc__promo section">
                <div className="container">
                    <div className="sc__ct">
                        <div className="sc__title">Мы создаем</div>
                        <div className="anim__box">
                            <div className={'anim__it anim__it--1' + (activePromo == 1 ? ' active' : '')}>
                                <div className="anim__it-bg"/>
                                <div className="sc__title">
                                    уникальные
                                </div>
                            </div>
                            <div className={'anim__it anim__it--2' + (activePromo == 2 ? ' active' : '')}>
                                <div className="anim__it-bg"/>
                                <div className="sc__title">
                                    творческие
                                </div>
                            </div>

                            {desktop && (
                                <div className={'anim__it anim__it--3' + (activePromo == 3 ? ' active' : '')}>
                                    <div className="anim__it-bg"/>
                                    <div className="sc__title">
                                        со всем вниманием
                                    </div>
                                </div>
                            )}

                            {!desktop && (
                                <>
                                    <div className={'anim__it anim__it--3' + (activePromo == 3 ? ' active' : '')}>
                                        <div className="anim__it-bg"/>
                                        <div className="sc__title">
                                            со всем
                                        </div>
                                    </div>
                                    <div className={'anim__it anim__it--4' + (activePromo == 4 ? ' active' : '')}>
                                        <div className="anim__it-bg"/>
                                        <div className="sc__title">
                                            вниманием
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="sc__title">истории</div>
                    </div>

                </div>
            </div>
            <div ref={ref} className="sc__showreel section" id="showreel">
                <div className="container">
                    <div id="video__box" className="video__box">
                        <div className="video__box-img">
                            <picture>
                                <source media="(min-width: 1024px)"
                                        srcSet={require("../assets/img/showreel-img.webp")}
                                        type="image/webp"
                                />
                                <source media="(min-width: 1024px)"
                                        srcSet={require("../assets/img/showreel-img.jpg")}
                                        type="image/jpg"
                                />
                                <img src={require("../assets/img/showreel-img.jpg")}/>
                            </picture>
                        </div>
                        <div className={'video__frame' + (videoLoaded ? ' loaded' : '')}>
                            <ReactPlayer url={require("../assets/video/showreel.mp4")} muted loop playsinline playing={loaded} onReady={()=>{
                                setVideoLoaded(true);
                            }}/>
                        </div>
                        <div className="video__box-ct d-flex align-items-center justify-content-center desk-only">
                            <div className="video__box-ct-bl">
                                <div style={{left: showreelXY.x, top: showreelXY.y}} id="showreel-anim" className="sc__header">
                                    <div className="showreel__logo">
                                        <img src={require("../assets/img/logo-media-wh.svg").default}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="about" className="sc__about section">
                <div className="container d-flex justify-content-end">
                    <div className="sc__ct">
                        <div className="d-flex">
                            <div className="sc__lbl d-flex align-items-center justify-content-center">
                                КТО МЫ
                            </div>
                        </div>
                        <div className="sc__subtitle">
                            Мы&nbsp;креативная команда, умеющая рассказывать <br/>
                            истории. Знаем, что такое качественный, интересный <br/>
                            и&nbsp;эмоциональный контент. Работаем с&nbsp;ведущими <br/>
                            VOD-сервисами, медиахолдингами <br/>
                            и&nbsp;интернет-изданиями России
                        </div>
                    </div>
                </div>
            </div>
            <div id="advants" className="sc__advants section">
                <div className="container">
                    <div className="sc__header">
                        <div className="sc__title">
                            Что мы <br/>
                            можем
                        </div>
                    </div>
                    <div className="advants__list" data-active={activeIndex}>
                        <div className="arrow__anim desk-only">
                            <svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="43.5" cy="43.5" r="43.5" fill="#70F9BB"/>
                                <path d="M35.5 29H57.5M57.5 29V50.5M57.5 29L26 60.5" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
                            </svg>
                        </div>
                        <div className="advants__it--wrap">
                            <div className="advants__it">
                                <div className="advants__it-icon">
                                    <svg width="121" height="92" viewBox="0 0 121 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 50.2308L48.6923 81L109.462 11" stroke="white" strokeWidth="22" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className="advants__it-descr">
                                    Мультиформатные проекты с&nbsp;интерактивными
                                    лендингами в&nbsp;уникальном дизайн-коде
                                </div>
                            </div>
                        </div>
                        <div className="advants__it--wrap">
                            <div className="advants__it">
                                <div className="advants__it-icon">
                                    <svg width="121" height="92" viewBox="0 0 121 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 50.2308L48.6923 81L109.462 11" stroke="white" strokeWidth="22" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className="advants__it-descr">
                                    Интерактивные лонгриды и&nbsp;мультижанровый
                                    текстовый сторителлинг
                                </div>
                            </div>
                        </div>
                        <div className="advants__it--wrap">
                            <div className="advants__it">
                                <div className="advants__it-icon">
                                    <svg width="121" height="92" viewBox="0 0 121 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 50.2308L48.6923 81L109.462 11" stroke="white" strokeWidth="22" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className="advants__it-descr">
                                    Документальные сериалы, над которыми работают
                                    лучшие журналисты-расследователи России. <br/>
                                    Сочетаем профессиональные съёмки и эффектный
                                    моушн-дизайн
                                </div>
                            </div>
                        </div>
                        <div className="advants__it--wrap">
                            <div className="advants__it">
                                <div className="advants__it-icon">
                                    <svg width="121" height="92" viewBox="0 0 121 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 50.2308L48.6923 81L109.462 11" stroke="white" strokeWidth="22" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className="advants__it-descr">
                                    Видеоролики для соцсетей: короткие и яркие <br className="desk-only"/>
                                    форматы для ВК, ОК, Telegram, Instagram. <br/>
                                    А также Shorts, Reels и Stories
                                </div>
                            </div>
                        </div>
                        <div className="advants__it--wrap">
                            <div className="advants__it">
                                <div className="advants__it-icon">
                                    <svg width="121" height="92" viewBox="0 0 121 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 50.2308L48.6923 81L109.462 11" stroke="white" strokeWidth="22" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className="advants__it-descr">
                                    Все форматы контента <br className="mb-only"/>и дизайн-коды  <br className="desk-only"/>
                                    для социальных сетей
                                </div>
                            </div>
                        </div>
                        <div className="advants__it--wrap">
                            <div className="advants__it">
                                <div className="advants__it-icon">
                                    <svg width="121" height="92" viewBox="0 0 121 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 50.2308L48.6923 81L109.462 11" stroke="white" strokeWidth="22" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className="advants__it-descr">
                                    Комплексный СММ и стратегии <br/>
                                    продвижения контента
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="projects" className="sc__projects section">
                <div className="container">
                    <div className="sc__header d-flex justify-content-between">
                        <div className="sc__title">
                            Наши <br/>
                            проекты
                        </div>
                    </div>
                    <div className="projects__sl-box">
                        {desktop && (
                            <>
                                <div className="projects__data-sl">
                                    {projects.map((pr, i) => (
                                        <div className={'projects__data-sl-it fadeIn animated' + (prCur == (i + 1) ? ' active' : '')}>
                                            <div className="dots">
                                                {projects.map((pr, i) => (
                                                    <div onClick={()=>{
                                                        setPrCur(pr.id);
                                                        setTimeout(()=>{
                                                            setPrPrev(pr.id);
                                                        }, 600);
                                                    }} className={'dot' + (prCur == (i + 1) ? ' active' : '')}>

                                                    </div>
                                                ))}
                                            </div>
                                            <div className="projects__data-sl-it-logo">
                                                <img src={pr.logo}/>
                                            </div>
                                            <div className="projects__data-sl-it-title">
                                                {pr.title}
                                            </div>
                                            <div className="projects__data-sl-it-subtitle">
                                                {pr.descr}
                                            </div>
                                            <div className="project__tags d-flex">
                                                {pr.tags.map((tag, j) => (
                                                    <div className="project__tag text-uppercase d-flex align-items-center justify-content-center">
                                                        {tag}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="projects__next-links">

                                                {moveElementsToEndOfArray(projects, (i + 1)).slice(0, 2).map((prNxt, k) => (
                                                    <div className="projects__next-link">
                                                        <a onClick={()=>{
                                                            setPrCur(prNxt.id);
                                                            setTimeout(()=>{
                                                                setPrPrev(prNxt.id);
                                                            }, 600);
                                                        }}>{prNxt.title}</a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="projects__media-sl">
                                    {projects.map((pr, i) => (
                                        <a href={pr.url} target="_blank" className={'projects__media-sl-it' + ((prCur == (i + 1) || prPrev == (i + 1)) ? ' active' : '') + (prCur == (i + 1) ? ' current' : '')}>
                                            <div className="projects__media-sl-it-img">
                                                <picture>
                                                    <source srcSet={pr.prevWebp}
                                                            type="image/webp"
                                                    />
                                                    <source srcSet={pr.prevMbWebp}
                                                            type="image/webp"
                                                            media="(max-width: 1023px)"
                                                    />
                                                    <source srcSet={pr.prev}
                                                            type="image/jpg"
                                                    />
                                                    <source srcSet={pr.prevMb}
                                                            type="image/jpg"
                                                            media="(max-width: 1023px)"
                                                    />
                                                    <img src={pr.prev}/>
                                                </picture>
                                            </div>
                                            {(prCur == (i + 1) || prPrev == (i + 1)) && (
                                                <div className="projects__media-sl-it-video">
                                                    <ReactPlayer url={desktop ? pr.video : pr.videoMb} muted loop playsinline playing={loaded}/>
                                                </div>
                                            )}
                                            <div className="projects__media-sl-it-ct d-flex align-items-center justify-content-center"/>
                                        </a>
                                    ))}
                                </div>
                            </>
                        )}

                        {!desktop && (
                            <div className="proj__sl-row">
                                <div ref={sliderRef} className="keen-slider">
                                    {projects.map((pr, i) => (
                                        <div className="keen-slider__slide">
                                            <div className="proj__it">
                                                <div className="proj__it-main">
                                                    <div className="proj__it-img">
                                                        <picture>
                                                            <source srcSet={pr.prevWebp}
                                                                    type="image/webp"
                                                            />
                                                            <source srcSet={pr.prevMbWebp}
                                                                    type="image/webp"
                                                                    media="(max-width: 1023px)"
                                                            />
                                                            <source srcSet={pr.prev}
                                                                    type="image/jpg"
                                                            />
                                                            <source srcSet={pr.prevMb}
                                                                    type="image/jpg"
                                                                    media="(max-width: 1023px)"
                                                            />
                                                            <img src={pr.prev}/>
                                                        </picture>
                                                        <div className="proj__it-video">
                                                            <ReactPlayer url={desktop ? pr.video : pr.videoMb} muted loop playsinline playing={loaded}/>
                                                        </div>
                                                    </div>
                                                    <div className="project__tags d-flex">
                                                        {pr.tags.map((tag, j) => (
                                                            <div className="project__tag text-uppercase d-flex align-items-center justify-content-center">
                                                                {tag}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="proj__it-ct">
                                                    <div className="proj__it-logo">
                                                        <img src={pr.logo}/>
                                                    </div>
                                                    <div className="proj__it-title">
                                                        {pr.title}
                                                    </div>
                                                    <div className="proj__it-descr">
                                                        {pr.descr}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                    </div>
                    <div className="sc__action d-flex justify-content-end">
                        <div onClick={()=>{
                            setRedirect(true);
                            setTimeout(()=>{
                                navigate('/projects');
                            }, 750);
                        }} className="btn__arrow d-flex align-items-center justify-content-center">
                            <span>Все проекты</span>
                            <i className="icon__right">
                                <svg width="91" height="36" viewBox="0 0 91 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M74 1.5C79.8232 7.50521 88.6892 16.6482 89.8687 17.8646C89.944 17.9422 89.9402 18.0617 89.865 18.1392L74 34.5" stroke="#050505" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M1.5 17.25C1.08579 17.25 0.75 17.5858 0.75 18C0.75 18.4142 1.08579 18.75 1.5 18.75V17.25ZM90 17.25H1.5V18.75H90V17.25Z" fill="#050505"/>
                                </svg>
                            </i>
                        </div>
                    </div>
                </div>
            </div>
            <div id="clients" className="sc__partners section">
                <div className="container">
                    <div className="sc__header">
                        <div className="sc__title">
                            Наши клиенты <br/>
                            и партнеры
                        </div>
                    </div>
                    <div className="partners__list">
                        <div className="partners__list-row">
                            <div ref={sliderRef2} className="keen-slider">
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://ири.рф/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-1.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://okko.tv/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-2.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://lenta.ru/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-3.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://vk.com/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-4.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://premier.one/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-5.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://www.gazeta.ru/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-6.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://rsv.ru/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-7.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://www.kinopoisk.ru/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-8.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://iz.ru/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-9.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://ok.ru/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-10.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://sovcombank.ru/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-11.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://ya.ru/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-12.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://russian.rt.com/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-13.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://www.kp.ru/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-14.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://home.bank/" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-15.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="partners__it keen-slider__slide">
                                    <div className="partners__it-bl d-flex align-items-center justify-content-center">
                                        <div className="partners__it-ct">
                                            <a href="https://www.r-pharm.com/ru" target="_blank" className="partners__it-logo">
                                                <img src={require("../assets/img/p-logo-16.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {slLoaded2 && instanceRef2.current && (
                            <div onClick={(e) =>
                                e.stopPropagation() || instanceRef2.current?.next()
                            } className="sl__nav sl__nav--next d-flex align-items-center justify-content-center mb-only">
                                <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.3283 0.783428L26.09 9.54505M26.09 9.54505L17.5275 18.1075M26.09 9.54505H0.999872" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="sc__contacts section">
                <div className="container d-flex align-items-center">
                    <div className="sc__ct">
                        <div className="row align-items-center">
                            <div className="col-6 col">
                                <div className="sc__title">
                                    Мы <br/>
                                    на связи
                                </div>
                            </div>
                            <div className="col-6 col">
                                <div className="sc__subtitle">
                                    Расскажите нам о&nbsp;вашем проекте <br/>
                                    и&nbsp;свяжитесь с&nbsp;нашей командой.
                                </div>
                                <div className="d-flex">
                                    <div onClick={()=>{
                                        setRedirect(true);
                                        setTimeout(()=>{
                                            navigate('/contacts');
                                        }, 750);
                                    }} className="btn__arrow d-flex align-items-center">
                                        <span>Связаться</span>
                                        <i className="icon__right">
                                            <svg width="91" height="36" viewBox="0 0 91 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M74 1.5C79.8232 7.50521 88.6892 16.6482 89.8687 17.8646C89.944 17.9422 89.9402 18.0617 89.865 18.1392L74 34.5" stroke="#050505" strokeWidth="1.5" strokeLinecap="round"/>
                                                <path d="M1.5 17.25C1.08579 17.25 0.75 17.5858 0.75 18C0.75 18.4142 1.08579 18.75 1.5 18.75V17.25ZM90 17.25H1.5V18.75H90V17.25Z" fill="#050505"/>
                                            </svg>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc__contacts-email">
                    <a href="mailto:projects@prosto-media.com">projects@prosto-media.com</a>
                </div>
            </div>
        </>
    )
}

export default Home;
